<template>
  <action-edit
    :action-url="`feed-connectors/nod/attribute-values/${item.id}/change`"
    :item="item"
    button-icon="fal fa-exchange"
    button-label="Inlocuieste atributul"
  >

    <template v-slot:title>
      Alege un atribut nou pentru: <strong>{{ item.value }}</strong>
    </template>

    <template v-slot:fields="{form, errors}">

      <div>
        <attribute-value-selector v-model="form.attribute_value_id" :attribute-id="item.attribute_id"/>
        <error-messages :errors="errors.attribute_value_id"/>
      </div>

    </template>

  </action-edit>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ActionEdit from '@/components/layout/ActionButtons/ActionEdit'
import AttributeValueSelector from '@/components/autocompletes/AttributeValueSelector'

export default {
  components: {
    AttributeValueSelector,
    ActionEdit,
    ErrorMessages
  },
  props: {
    item: {
      required: true
    }
  }
}
</script>
