<template>
  <action-edit
    :action-url="`feed-connectors/nod/attribute-values/${item.id}/add-new`"
    :item="item"
    button-icon="fal fa-plus-square"
    button-label="Adauga atribut nou"
  >

    <template v-slot:title>
      Adauga atribut nou pentru atributul: <strong>{{ item.value }}</strong>
    </template>

    <template v-slot:fields="{form, errors}">

      <div>
        <v-text-field v-model="form.name" dense hide-details outlined placeholder="Nume" single-line/>
        <error-messages :errors="errors.name"/>
      </div>

    </template>

  </action-edit>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ActionEdit from '@/components/layout/ActionButtons/ActionEdit'

export default {
  components: {
    ActionEdit,
    ErrorMessages
  },
  props: {
    item: {
      required: true
    }
  }
}
</script>
